<template>
    <Body class="font-primary text-primary">
        <ClientOnly>
            <OrganismsGlobalAppHeader :is-light="true" />
            <div class="page-container">
                <slot></slot>
            </div>
        </ClientOnly>
    </Body>
</template>
<script setup>

if (useCart().isEmpty.value) {
      useRouter().push(useAppConfig().definedPageUrls.emptyCart);
}

</script>